import type { ComponentStyleConfig } from "@chakra-ui/react";

import { sizes } from "./text";

export const Textarea: ComponentStyleConfig = {
  variants: {
    outline: {
      color: "textPrimary",
      borderWidth: 1,
      borderColor: "border.500",
      borderRadius: "lg",
      resize: "none",
      bgColor: "background.500",
      fontSize: sizes["body.md"].fontSize,
      fontWeight: sizes["body.md"].fontWeight,
      px: 3,
      _placeholder: {
        color: "textTertiary",
      },
      _focus: {
        borderColor: "primary.500",
        boxShadow: "none",
      },
      _hover: {
        borderColor: "borderSecondary.500",
      },
    },
  },
  defaultProps: {
    variant: "outline",
  },
};
