import { useMemo, useRef } from "react";
import _ from "lodash";

export default function useDeepMemo<T>(value: T): T {
  const lastValueRef = useRef(value);
  return useMemo(() => {
    if (!_.isEqual(lastValueRef.current, value)) {
      lastValueRef.current = value;
    }
    return lastValueRef.current;
  }, [value]);
}
