import { ChangeEvent, useEffect, useState } from "react";
import React from "react";
import usePrevious from "domains/commons/hooks/usePrevious";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { PLANS } from "domains/teams/constants/Pricing";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { useUserContext } from "domains/user/contexts/UserProvider";
import _ from "lodash";

import {
  Checkbox,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const SUPPORT_CATEGORIES = {
  bug: { label: "Technical Issues", hubspotId: "PRODUCT_ISSUE" },
  subscription: {
    label: "Billing & Subscription Issues",
    hubspotId: "BILLING_ISSUE",
  },
  general: { label: "General Inquiries", hubspotId: "GENERAL_INQUIRY" },
  other: { label: "Other", hubspotId: "MISC" },
};
const INITIAL_SUPPORT_FORM = {
  email: "",
  name: "",
  categories: [],
  message: "",
};

type SupportForm = {
  email: string;
  name: string;
  categories: (keyof typeof SUPPORT_CATEGORIES)[];
  message: string;
};

// ------------------------------------

export interface SupportFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SupportFormModal({
  isOpen,
  onClose,
}: SupportFormModalProps) {
  const { user } = useUserContext();
  const { selectedTeam } = useTeamContext();
  const { successToast, errorToast } = useScenarioToast();
  const [form, setForm] = useState<SupportForm | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const prevIsOpen = usePrevious(isOpen);

  const isReady = !!form;
  const supportFormId =
    PLANS[selectedTeam.plan as keyof typeof PLANS].options?.supportFormId;
  const isFormFilled = !!(
    form &&
    form.email &&
    form.name &&
    form.categories.length > 0 &&
    form.message
  );

  // ----------------------------------

  const handleInputChange = (key: keyof SupportForm, value: any) => {
    setForm(
      (form) =>
        form && {
          ...form,
          [key]: value,
        }
    );
  };

  const handleSubmitClick = async () => {
    if (!supportFormId) return;
    setIsLoading(true);

    try {
      const requestUrl = `https://api.hsforms.com/submissions/v3/integration/submit/20082853/${supportFormId}`;
      const requestBody = {
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: form?.email,
          },
          {
            objectTypeId: "0-1",
            name: "firstname",
            value: form?.name,
          },
          {
            objectTypeId: "0-5",
            name: "hs_ticket_category",
            value: form?.categories
              .map((key) => SUPPORT_CATEGORIES[key].hubspotId)
              .join(";"),
          },
          {
            objectTypeId: "0-5",
            name: "subject",
            value: selectedTeam.id,
          },
          {
            objectTypeId: "0-5",
            name: "content",
            value: form?.message,
          },
        ],
      };

      const response = await fetch(requestUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.status === 200) {
        successToast({
          title: "Your message was successfully sent",
          description: "We will reach you as soon as possible.",
        });
      } else {
        throw new Error("HUBSPOT_API_ERROR");
      }
    } catch (err) {
      errorToast({
        title: "There was an error sending the form, please try again",
      });
      Sentry.captureException(err);
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  // ----------------------------------

  useEffect(() => {
    if (prevIsOpen === isOpen) return;
    if (isOpen) {
      setForm({ ...INITIAL_SUPPORT_FORM, email: user?.email || "" });
    } else {
      setForm(undefined);
    }
  }, [prevIsOpen, isOpen, user?.email, setForm]);

  // ----------------------------------

  return (
    <Modal isOpen={isOpen && isReady} onClose={onClose} variant="modern">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" textAlign="left" spacing={6}>
            <SectionTop />
            {!!form && (
              <SectionContent onInputChange={handleInputChange} form={form} />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            data-testid="submit-button"
            isDisabled={!isFormFilled}
            isLoading={isLoading}
            onClick={handleSubmitClick}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

function SectionTop() {
  return (
    <VStack align="flex-start" pt={6} spacing={6}>
      <Image
        alt="modal illustration"
        filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
        src="/ui/contact-us.svg"
      />
      <VStack align="start" spacing={3}>
        <Heading data-testid="top-title-text" size="lg">
          Contact Us
        </Heading>
        <Text
          color="textSecondary"
          data-testid="top-title-secondary-text"
          size="body.lg"
        >
          Please submit this form and we will reach you as soon as possible.
        </Text>
      </VStack>
    </VStack>
  );
}

// ------------------------------------

interface SectionContentProps {
  form: SupportForm;
  onInputChange: (key: keyof SupportForm, value: any) => void;
}

function SectionContent({ form, onInputChange }: SectionContentProps) {
  const handleCategoryCheckboxChange = (
    key: keyof typeof SUPPORT_CATEGORIES,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    const newCategories = isChecked
      ? _.uniq([...form.categories, key])
      : _.without(form.categories, key);
    onInputChange("categories", newCategories);
  };

  return (
    <VStack align="flex-start" spacing={3}>
      <VStack align="start" w="100%" spacing={1}>
        <Text size="body.md">Email</Text>
        <ScenarioInput
          setValue={(value) => onInputChange("email", value)}
          value={form.email}
          bgColor="background.500"
        />
      </VStack>

      <VStack align="start" w="100%" spacing={1}>
        <Text size="body.md">Name</Text>
        <ScenarioInput
          setValue={(value) => onInputChange("name", value)}
          value={form.name}
          bgColor="background.500"
        />
      </VStack>

      <VStack align="start" w="100%" spacing={1}>
        <Text pb={1} size="body.md">
          What Do You Need Help With?
        </Text>
        {(
          Object.keys(SUPPORT_CATEGORIES) as Array<
            keyof typeof SUPPORT_CATEGORIES
          >
        ).map((categoryKey) => {
          const category = SUPPORT_CATEGORIES[categoryKey];
          const isChecked = form.categories.includes(categoryKey);
          return (
            <Checkbox
              key={categoryKey}
              isChecked={isChecked}
              onChange={(e) => handleCategoryCheckboxChange(categoryKey, e)}
            >
              <Text
                color={isChecked ? "textPrimary" : "textSecondary"}
                size="body.md"
              >
                {category.label}
              </Text>
            </Checkbox>
          );
        })}
      </VStack>

      <VStack align="start" w="100%" spacing={1}>
        <Text size="body.md">Please Detail Your Concern</Text>
        <Textarea
          onChange={(e) => onInputChange("message", e.target.value)}
          placeholder="Write your message..."
          value={form.message}
        />
      </VStack>
    </VStack>
  );
}
