export type GuideKeys =
  | "inference-intro"
  | "inference-parameter-prompts"
  | "inference-parameter-reference-image"
  | "inference-parameter-reference-image-mode-image2image"
  | "inference-parameter-reference-image-mode-controlnet"
  | "inference-parameter-reference-image-mode-character-reference"
  | "inference-parameter-reference-image-mode-style-reference"
  | "inference-parameter-reference-image-mode-dual"
  | "inference-parameter-others"
  | "inference-parameter-controlnet-structure"
  | "inference-parameter-controlnet-pose"
  | "inference-parameter-controlnet-depth"
  | "inference-parameter-controlnet-segmentation"
  | "inference-parameter-controlnet-illusion"
  | "inference-parameter-controlnet-character"
  | "inference-parameter-controlnet-landscape"
  | "training-intro"
  | "training-type-style"
  | "training-type-subject"
  | "training-type-custom"
  | "training-parameter-learning-rate"
  | "training-parameter-steps"
  | "training-parameter-text-encoder-ratio"
  | "upscale-intro"
  | "upscale-parameter-image"
  | "upscale-parameter-scaling-factor"
  | "upscale-parameter-prompts"
  | "upscale-parameter-others"
  | "composition-intro"
  | "composition-models"
  | "composition-tests"
  | "vectorize-intro"
  | "pixelate-intro"
  | "restyle-intro"
  | "texture-intro"
  | "skybox-intro"
  | "canvas-live"
  | "remove-background-intro";

type Guides = {
  [key in GuideKeys]: {
    steps: {
      title: string;
      description: string;
      imageFormat?: string;
    }[];
    knowledgeCenterLink: string;
  };
};

export const GUIDES: Guides = {
  "inference-intro": {
    steps: [
      {
        title: "Quick Guide to Generating",
        description:
          "There are many ways to leverage image generation on Scenario - from simple to advanced. Here are a few recommended tools for new or experienced users.",
      },
      {
        title: "Choose the Right Model",
        description:
          "Select a model tailored to your specific goal for more effective results. Easily pick and switch between different models using the menu on the upper left of the generation interface.",
      },
      {
        title: "Create Your Perfect Model",
        description:
          "If none of the available platform models fit your needs, you can either train a new model from scratch or combine existing ones into a new one. These powerful tools enable you to craft models specifically tailored to your style and requirements.",
      },
      {
        title: "Crafting Prompts",
        description:
          'The prompt is your main tool for instructing the AI model on what to create. When unsure how to write a good prompt, use "Prompt Spark," which helps generate well-structured and inspiring suggestions using your model\'s training data and pinned images.',
      },
      {
        title: "Reference Image",
        description:
          "Use a Reference Image to guide the generation process. Various reference modes are available to refine and enhance your results.",
      },
      {
        title: "Additional Settings",
        description:
          "Lastly, you can tweak the overall output with additional settings such as Sampling Steps, Guidance, Dimensions, changing the Scheduler, and using a Seed. Adjust these settings to refine your results.",
      },
      {
        title: "Additional Settings",
        description:
          "There are many ways to generate and edit creative content in Scenario. Use all available tools and features to achieve the best results. For more detailed information, visit our Knowledge Center.",
      },
    ],
    knowledgeCenterLink: "https://help.scenario.com/en/c/generate-images",
  },
  "inference-parameter-prompts": {
    steps: [
      {
        title: "Prompting Best Practices",
        description:
          "Make the most of prompting; guide generations skillfully with these tips. There are some strategies that work better than other when a user is trying to maintain a custom style.",
      },
      {
        title: "Prompting Custom Models",
        description:
          "It's crucial to understand that prompting a custom model requires specific attention to detail. Unlike base models, each finetuned model responds uniquely to prompts. Tailor your prompts carefully to achieve the best results.",
      },
      {
        title: "Use an Opening Statement",
        description:
          "Start with an unbroken opening statement that describes the overall scene in detail.<br><br>*Ex. A boy holding a red balloon on a cloudy day*",
      },
      {
        title: "Add Supporting Descriptors",
        description:
          "Test the outcome of the first prompt and add words to describe the style, aesthetic and supporting details.<br><br>Ex. *A boy holding a red balloon on a cloudy day, city, blonde hair, cinematic lighting*",
      },
      {
        title: "Use Prompt Expressions",
        description:
          "If details need to be given more or less weight than rest of the prompt, put them in () and add + or - after the parenthesis.<br><br>Ex. *(wearing a blue jacket)++, (short)-*",
      },
      {
        title: "Explore Negative Prompts",
        description:
          "The **Negative Prompt** indicates words and concepts that should be excluded from the final image. It is useful to add words that relate to the quality of the image as well as objects a user wants to avoid.<br><br>Ex. *messy, digital art, oversaturated, bright*",
      },
      {
        title: "Start Prompting - and beyond",
        description:
          "Prompting can be a very powerful tool, but it will have limitations. If a user finds they cannot control their outcome with prompts alone, it is time to look at employing reference images or improving the model being used.",
      },
      {
        title: "Prompt Spark",
        description:
          "When you're uncertain how to prompt a custom-trained model, let Prompt Spark assist you. It leverages the model's training data and pinned images to generate inspiring prompts.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/prompt-expressions",
  },
  "inference-parameter-reference-image": {
    steps: [
      {
        title: "Introduction to Reference Images",
        description:
          "The purpose of uploading a **reference image** is to guide the AI model with pictures, along with words. The way that the model is guided changes based on what settings you select.",
      },
      {
        title: "Image to Image",
        description:
          "By default, the reference image is set to **Image to Image**. This setting imposes the colors and shapes of the reference image on the final generation.",
      },
      {
        title: "Sketch",
        description:
          "In addition to uploading images from a device or using Scenario generated images, users can also sketch their own reference image or make direct edits to an uploaded image using the **Sketch** feature.",
      },
      {
        title: "Character Reference",
        description:
          "With a more advanced understanding of pictures than Image to Image, the **Character Reference** is able to understand context and re-imagine the subject of your reference image.",
      },
      {
        title: "Style Reference",
        description:
          "With the same principle as **Character Reference**, the **Style Reference** is able to understand the style of your reference image and apply it to the final generation. This is particularly useful for color and texture matching.",
      },
      {
        title: "ControlNet and Dual References",
        description:
          "**Controlnet** focuses on structural elements of your image, and has a variety of options from **Pose** to **Depth**.<br><br>It is also possible to use **multiple reference images** in different modes for more control of the generated images. Click on the different reference image controls for more information on each type.",
      },
      {
        title: "Additional Controls",
        description:
          "Don't forget! You can remove background and crop your image directly in the reference image window. Just click the ellipses on the upper right hand side of the image.<br><br>Click below for a more in depth explanation of our default setting, Image to Image.",
      },
    ],
    knowledgeCenterLink: "https://help.scenario.com/en/c/reference-images",
  },
  "inference-parameter-reference-image-mode-image2image": {
    steps: [
      {
        title: "Image to Image",
        description:
          "**Image to Image** is a simple and effective tool for controlling the color, shape and general subject of a generation with a reference image.",
      },
      {
        title: "Low Influence",
        description:
          "When the reference image strength is set to 30 or lower, the style of the model being used and the prompt carry more weight.",
      },
      {
        title: "Medium Influence",
        description:
          "When the reference image strength is set between 30 and 60, the color, line work and shapes of the reference image are taken into account more equally with the model and prompt.",
      },
      {
        title: "High Influence",
        description:
          "When the reference image strength is set near and above 60, the reference image has more influence over the final generation than any other factors.",
      },
      {
        title: "Final Notes",
        description:
          '**Image-to-Image** is not an "image prompt", meaning that the AI takes very little detail or context information from the image itself compared with other reference image types.<br><br>This tool is great for controlling the overall shape and color of an image, making small iterative refinements to a generation, and very basic re-skinning of an image.',
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/single-reference-image-img2img",
  },
  "inference-parameter-reference-image-mode-controlnet": {
    steps: [
      {
        title: "ControlNet",
        description:
          "**ControlNet** is a tool that maps specific aspects of a reference image, and then uses that map to guide results in the outputs.",
      },
      {
        title: "Influence",
        description:
          "Much like **Image to Image**, the strength modifier gives preference to the **Reference Image** when adjusted to a high number and more preference to the prompt and model when set to a lower number.<br><br>The recommended range for ControlNet depends on the mode and user goals.",
      },
      {
        title: "Modes and more...",
        description:
          "Click on the individual modes for a brief description of how each one functions. If you would like to use ControlNet with an Image to Image, Character Reference, or Style Reference, choose one of those options in the dropdown for an explanation of how they work.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-reference-image-mode-character-reference": {
    steps: [
      {
        title: "Character Reference",
        description:
          "The term **Character Reference** pulls visual features from a reference image and inserts them into the resulting generation.",
      },
      {
        title: "Prompting",
        description:
          "The **Character Reference makes the reference image a part of the overall prompt for your character**. This means that a user can use simpler prompting methods.<br><br>For example, if the user has a reference image of a woman and wants to add a hat using Character Reference, they can simply prompt:<br><br>*Wearing a hat*",
      },
      {
        title: "Variable Influence",
        description:
          "Similar to **Image to Image**, a lower Influence value gives less weight to the reference image, while a higher value gives more weight.",
      },
      {
        title: "Character Creation",
        description:
          "The **Character Reference** works very well for creating character training images. Learn more about character creation with Character Reference on the Knowledge Center.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/character-reference",
  },
  "inference-parameter-reference-image-mode-style-reference": {
    steps: [
      {
        title: "Style Reference",
        description:
          "Style Reference extracts the overall style from a reference image, including visual features like color palette, texture, or shapes. It incorporates these elements into the generated content, applying the reference style to the prompt.",
      },
      {
        title: "Adapt Your Prompting",
        description:
          "Short prompts are effective. The AI automatically applies the style from the reference image. Focus on describing the subjects or elements first, and only prompt the style if needed.",
      },
      {
        title: "The Influence Matters",
        description:
          "Adjust the Style Reference influence for optimal results. Higher values can make the model behave like image-to-image (too ressemblant). Start around 25 and tweak up or down in small increments to find the perfect balance.",
      },
      {
        title: "Style Influences Strength",
        description:
          "If the style and training of the model being used resemble the reference image, then the strength value should be lower as well.<br><br>Alternatively, the less related the reference image is to the model’s style and subject, the more the strength value should be increased.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/style-reference",
  },
  "inference-parameter-reference-image-mode-dual": {
    steps: [
      {
        title: "Dual Reference Images",
        description:
          "On Scenario you can use **up to two reference images** and combine different tools to better guide generative outputs.<br><br>Dual Reference Images works best with 1:1 aspect ratio images.",
      },
      {
        title: "Image to Image + ControlNet",
        description:
          "If a user wishes to control both the color and general shape of an image with structural composition guidance, they may wish to employ Controlnet and Image to Image together.",
      },
      {
        title: "Image to Image + Style Reference",
        description:
          "Similarly, it is possible to control both the color and detail structure of an image, as well as transfer specific details of a second reference image using a combination of Image-to-Image transformation and transfer specific style elements like color, texture, and artistic effects.",
      },
      {
        title: "Image to Image + Character Reference",
        description:
          "For character reference, it is also possible to control both the color and detail structure of an image, and transfer specific attributes of a character reference image using a combination of Image-to-Image transformation and carry over specific visual details like faces, garments, and more using this combination.",
      },
      {
        title: "ControlNet + Style",
        description:
          "Similarly, combining ControlNet with a Style Reference enables you to impose an overall structure for your image and transfer specific style elements like color, texture, and artistic effects.",
      },
      {
        title: "ControlNet + Character",
        description:
          "Lastly, leveraging both ControlNet and the Character Reference allows you to impose an overall structure for your image and carry over specific visual details like faces, garments, and more using this combination.",
      },
      {
        title: "Exploration is Key",
        description:
          "Multi-image workflows are advanced use cases. It is recommended to familiarize yourself with the individual tools, to better understand how they might work. Take a look at the knowledge center for more ideas!",
      },
    ],
    knowledgeCenterLink: "https://help.scenario.com/en/articles/dual-reference",
  },
  "inference-parameter-others": {
    steps: [
      {
        title: "Additional Settings",
        description:
          "There are additional inference settings that you should know about. Click through to learn a little more about how they function.",
      },
      {
        title: "Image Count",
        description:
          "Adjust the **Image Slider** to launch more generations. Up to 16 images can be generated per batch (depending on your plan). ",
      },
      {
        title: "Sampling Steps",
        description:
          "The **Sampling Steps** are the number of rounds an image goes through the diffusion process. A range of 30-50 steps is recommended for most images. Although for some styles, higher sampling steps may be beneficial, **there is a point where more details do not benefit the end result, while costing more**.",
      },
      {
        title: "Guidance",
        description:
          "**Guidance** is an advanced setting that emphasizes a combination of the prompt and style being used. The default guidance value is recommended for most images, and you should test **small incremental changes**, if you do adjust the guidance setting.",
      },
      {
        title: "Dimensions",
        description:
          "You can change the dimensions using this dropdown. If you exceed 1280 px in height or width, there may be a risk of doubled objects. Taller or wider aspect ratios are recommended primarily for backgrounds and maps.",
      },
      {
        title: "Scheduler",
        description:
          "The Scheduler impacts how every sampling step is processed. The default scheduler works well with the majority of styles, and while you might benefit from testing different schedulers to find the best results for your model, this feature is not available for Flux.",
      },
      {
        title: "Seed",
        description:
          "The **Seed** targets a fixed spot in your model. Selecting a seed can allow you to test different settings while maintaining a constant, and also in some cases reproduce some similar compositional elements.",
      },
    ],
    knowledgeCenterLink: "https://help.scenario.com/en/c/generation-settings",
  },
  "inference-parameter-controlnet-structure": {
    steps: [
      {
        title: "Structure",
        description:
          'ControlNet Structure generates a "Canny" Edge Map of your image, which outlines the overall structure and distinct edges within it. This map then guides the image generation. This feature serves as a versatile mode for image reference.',
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-pose": {
    steps: [
      {
        title: "Pose",
        description:
          "Pose identifies and maps the pose and facial structure of a character. This pose then guides the image generation process. This feature is primarily designed for humanoid characters and works best with realistic inputs.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-depth": {
    steps: [
      {
        title: "Depth",
        description:
          "**ControlNet Depth** creates a depth map of what it detects in the reference image. This depth map guides the image generation.<br><br>Avoid flat-lay designs and illustrations that exhibit no depth.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-segmentation": {
    steps: [
      {
        title: "Segmentation",
        description:
          "**ControlNet Segmentation** detects the foreground, background, and various objects within an image, and creates a colorful segment map of them. These segments guide the image generation.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-illusion": {
    steps: [
      {
        title: "Illusion",
        description:
          "**ControlNet Illusion** detects the most prominent pattern in the reference image and the resulting map guides the placement of objects in the resulting image. Best results occur between 50-75 influence.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-character": {
    steps: [
      {
        title: "Character Mode",
        description:
          "ControlNet Character is a Scenario preset designed to capture both the pose and structural nuances of a character, whether face or body. This setting is particularly effective for creating character variants.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "inference-parameter-controlnet-landscape": {
    steps: [
      {
        title: "Landscape",
        description:
          "**ControlNet Landscape** is a Scenario designed preset that combines multiple modes to follow the depth of field and structural details in a landscape image.<br><br>This setting works best with landscape scenes without a lot of characters present.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/learning-controlnet",
  },
  "training-intro": {
    steps: [
      {
        title: "Start Training Custom AI Models",
        description:
          "Learn how to train AI models using your own data. This guide provides a step-by-step approach to creating proprietary AI models designed for consistent and guided generations.",
      },
      {
        title: "Choose a Preset (When available)",
        description:
          "Consider the objectives for your model and decide whether to focus on a specific Style (like Cartoon, Anime, Isometric) or a particular Subject (such as a consistent character).",
      },
      {
        title: "Style Training",
        description:
          'If the training images display a consistent visual style with various backgrounds or subjects, choose the "Style" preset.',
      },
      {
        title: "Subject Training",
        description:
          "Use the **Subject** preset if your training images consistently feature a specific character, object, or item (such as a weapon, outfit, or vehicle). This preset maintains uniform characteristics of the subject across generations.",
      },
      {
        title: "When in Doubt...",
        description:
          "Not sure what to choose? <br>Try training twice, both a **Style** and **Subject** model. Test each and choose whichever works best.",
      },
      {
        title: "Time to Begin!",
        description:
          "The best way to learn is by doing - start training now! For more in-depth information, **visit our Knowledge Center**.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-training-interface",
  },
  "training-type-style": {
    steps: [
      {
        title: "Tips for Style Training",
        description:
          "Use the **Style** preset to reproduce a style. This preset works best when training images have a consistent style.",
      },
      {
        title: "Training Images",
        description:
          "A typical training dataset for the **Style** preset includes 10-25 images that all show the same style, but have different subjects or scenes. Depending on the number of images, training can take 15 minutes to a couple hours.",
      },
      {
        title: "Captioning",
        description:
          'For "**Style**" training, the automatic captions are often sufficient, however feel free to edit them or add more descriptive details, to improve the training even further.',
      },
      {
        title: "Time to Begin!",
        description:
          "The best way to learn is by doing - start training now! For a deeper dive, **visit our Product Documentation**.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/training-a-style-lora",
  },
  "training-type-subject": {
    steps: [
      {
        title: "Subject Preset",
        description:
          "Use the **Subject** preset if your training images consistently feature a specific character, object, or item (such as an animal, weapon, outfit, or vehicle). This preset maintains uniform characteristics of the subject across generations.",
      },
      {
        title: "Training Images",
        description:
          "A standard training dataset for the **subject** preset includes 7-20 images that show the same character or object at different angles, zoom levels, face expressions, or situations.",
      },
      {
        title: "Captioning",
        description:
          "Automated captions are a great starting point for Subject training. You can further optimize results with manual captions. Try adding descriptive details of the subject or character in your captions.",
      },
      {
        title: "Prompts & Captions Work in Tandem",
        description:
          'As a general rule, don\'t caption elements that will always remain in the images. For example, if your training images feature a character with sunglasses and you want to keep the sunglasses, avoid captioning them.<br><br>Similarly, when training on a style, avoid captioning the style itself, like adding "comic style" or "anime." Only caption the elements that you intend to change or vary in the future.',
      },
      {
        title: "Time to Begin!",
        description:
          "The best way to learn is by doing—start training now! For a deeper dive, visit our use case documentation.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/training-a-character-lora",
  },
  "training-type-custom": {
    steps: [
      {
        title: "Advanced Custom Training",
        description:
          "Use Custom settings by modifying the training parameters beyond the presets. Settings allow for more precise customization but require a thorough understanding of the training process. Using custom settings is recommended for advanced users only.",
      },
      {
        title: "Best Practices",
        description:
          "Some advice for users who are considering adjusting their training parameters:<br>- Try small, incremental adjustments.<br>- It is not typical to need to adjust the sliders to the high and low extremes, and may produce undesirable results.",
      },
      {
        title: "Before You Start",
        description:
          "It is recommended to improve your captions and your dataset while still using the Scenario presets before adjusting training settings.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-training-interface",
  },
  "training-parameter-learning-rate": {
    steps: [
      {
        title: "Learning Rate",
        description:
          "The **Learning Rate** has to do with how fast or slow the AI learns and contextualizes the training data (dataset images and captions).",
      },
      {
        title: "UNet and Text Encoder ",
        description:
          "In general the **UNet** controls the amount in which the images in your dataset are trained in your model and the **Text Encoder** takes into account the captions provided.",
      },
      {
        title: "Best Practices",
        description:
          "If you are considering adjusting the learning rate, here are some best practices:<br>- Make small incremental adjustments.<br>- When increasing the learning rate, decrease the training steps, and vice versa.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-training-interface",
  },
  "training-parameter-steps": {
    steps: [
      {
        title: "Training Steps",
        description:
          "The **Training Steps** refer to how many cycles the AI takes to go through all the training data. The **Text Encoder Training Ratio** represents what percentage of the total steps are spent on the **text encoder**.",
      },
      {
        title: "Consider the Training Images",
        description:
          'Smaller training sets typically require fewer training steps than larger datasets.<br>For more detailed information, visit <a href="https://help.scenario.com/en/search?q=flux" style="color: var(--chakra-colors-primary-500)">our Knowledge Center</a>.',
      },
      {
        title: "Adjust Incrementally",
        description:
          'Scenario provides optimal training steps based on the model.<br>For large datasets, e.g., 30+ images, you may try reducing the number of steps.<br>For more detailed information, visit <a href="https://help.scenario.com/en/search?q=flux" style="color: var(--chakra-colors-primary-500)">our Knowledge Center</a>.',
      },
      {
        title: "Best Practices",
        description:
          "If you are considering adjusting the training steps, here are some best practices:<br>- Make small incremental adjustments.<br>- When increasing the learning rate, decrease the training steps, and vice versa.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-training-interface",
  },
  "training-parameter-text-encoder-ratio": {
    steps: [
      {
        title: "Text Encoder Training Ratio",
        description:
          "The **Text Encoder Training Ratio** represents what percentage of the total **Training Steps** are used to compare the **image captions** with the **training images**.",
      },
      {
        title: "Text Encoder Favors Captions",
        description:
          "Increasing the Text Encoder Training Ratio strengthens the influence of the text encoder, leading to a more significant impact of captions on the model's training process.",
      },
      {
        title: "Clarifying Concepts",
        description:
          "**The AI model already understands a lot of concepts**. Training the text encoder **does not** typically teach brand new concepts, instead it helps to make the relationship between what the AI knows, and what it does not recognize in your image more clear. ",
      },
      {
        title: "Styles: Lower Training Ratio",
        description:
          "Because the AI already knows many concepts, it is typically advised to keep the text encoder training ratio **lower for style training**.",
      },
      {
        title: "Subjects: Higher Training Ratio",
        description:
          "Conversely, it can help to **increase the ratio for subject training**, where the concept being learned has very specific details which need to remain incredibly consistent.",
      },
      {
        title: "Final Thoughts",
        description:
          "The **Text Encoder Training Ratio** is considered an advanced parameter; the more you understand how AI models are trained, the more creative your can be with advanced parameters.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-training-interface",
  },
  "upscale-intro": {
    steps: [
      {
        title: "Welcome to the Scenario Enhancer",
        description:
          "This tool offers the ability to Enhance and create on top of your images. Selecting a Preset will adjust the creativity level of your Enhance.",
      },
      {
        title: '"Precise" Presets',
        description:
          '"Precise" maintains output closely matches the input. Besides the additional pixels, your image/style is largely unchanged.',
      },
      {
        title: '"Creative" Presets',
        description:
          '"Creative" on the other hand, not only enhances but also adds new details to the image, occasionally transforming it into something entirely new.',
      },
      {
        title: '"Balanced" Presets',
        description:
          '"Balanced" strikes a middle ground, and is often the ideal adjustment to fix minor issues when an image generation isn\'t quite perfect yet.',
      },
      {
        title: "Tips",
        description:
          "Some combinations of Styles and Presets can be even more creative. Feel free to experiment and increase the Creativity Slider and Decrease the Image Fidelity to bring out even more interesting iterations.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/enhance-and-upscale",
  },
  "upscale-parameter-image": {
    steps: [
      {
        title: "Select a Custom Style",
        description:
          'You can opt for a specific "Style" such as "Comic", "Cartoon", "3D Rendered" and more.<br><br>Styles are ideally matched with your input image (e.g., if your input has a comic style, select "Comic"). However, they can also guide creative enhancing in a preferred direction.',
      },
      {
        title: '"Standard" Mode',
        description:
          "Standard is a general purpose setting with a focus on enhanced details and favoring a boost in realism.",
        imageFormat: "gif",
      },
      {
        title: "Styles and Presets Work Together",
        description:
          "By matching the Style of your image to the selected Style from the menu, you can then pair these settings with a preset to control the amount of additional creativity you’d like to the AI to add.",
        imageFormat: "gif",
      },
      {
        title: "Style + Creative",
        description:
          "By pairing a Style with the Creative preset, you can greatly transform your input image into a new creation.",
        imageFormat: "gif",
      },
      {
        title: "Learn More",
        description:
          "To learn more about the individual styles, visit our Knowledge Center",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/enhance-and-upscale",
  },
  "upscale-parameter-scaling-factor": {
    steps: [
      {
        title: "Choose a Scaling Factor",
        description:
          'Select a scale factor, ranging from 2x to 16x (up to a maximum 10k resolution). Keep in mind: the higher the factor, the more creativity it brings forth!<br><br>Explore gradually: begin with "2x/4x" and  progress from here.',
      },
      {
        title: "If the AI is creating too many artifacts",
        description:
          "You can reduce the Creativity slider, or manually enhance 2x multiple times and adjust the Preset to your desired amount of Creativity each time.",
      },
      {
        title: "If the AI is creating unwanted faces",
        description:
          "You can increase the Image Fidelity slider, or manually enhance 2x multiple times and adjust the Preset to your desired amount of Creativity each time.",
      },
      {
        title: "If a solid background shows tiling",
        description: "Remove the Solid Color Background before Enhancing.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/enhance-and-upscale",
  },
  "upscale-parameter-prompts": {
    steps: [
      {
        title: "Prompt Can Drive Styles & Details",
        description:
          "You can easily create your own enhancing style or adjust a preset by entering a **Prompt** or a **Negative Prompt** into their boxes.",
      },
      {
        title: "Describe the Image",
        description:
          "Guide the enhancement by describing the image you are enhancing in the prompt. If the image was generated using AI, you can use (or edit) the same prompt the image was generated with.",
      },
      {
        title: "Describe the Style",
        description:
          "Describe the style of the image or the style you want to influence the image with. Adjusting the prompt fidelity will add more weight to your prompt.",
      },
      {
        title: "Negative Prompt",
        description:
          'Start with a simple negative prompt such as: "blurry, ugly, duplicate, poorly drawn, deformed, mosaic"; if the image output has unwanted elements, describe them in your negative prompt.',
      },
      {
        title: "High Creativity Values",
        description:
          "High values on the Creativity slider will naturally create unexpected additions and creative hallucinations to your image. If you want an enhancement that is true to the original image, select the **Precise** preset.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/enhance-and-upscale",
  },
  "upscale-parameter-others": {
    steps: [
      {
        title: "Adjust the Settings",
        description:
          "The Scenario Upscaler offers unprecedented control over your upscales. There is endless possibility for experimentation and creative exploration.",
      },
      {
        title: "Prompt Fidelity",
        description:
          "The **Prompt Fidelity** will prioritize the prompt over the image at higher values. A value of 0.5 gives equal priority to the image being upscaled and the prompt or style.",
      },
      {
        title: "Image Fidelity",
        description:
          "**Image Fidelity** refers to the structural elements and shapes in your image. Higher **Image Fidelity** values will more closely maintain the original structure of the input image.",
      },
      {
        title: "Creativity",
        description:
          "**Creativity** controls how much freedom the AI has to imagine and create new elements for your image. A higher value will be more creative.",
      },
      {
        title: "Seed",
        description:
          "A Seed number targets a very specific image generation.<br><br>Using a specific seed number can provide enhanced control. For instance, you can keep the seed of an image you like, and experiment with various presets to create close variants.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/enhance-and-upscale",
  },
  "composition-intro": {
    steps: [
      {
        title: "Start Composing Models",
        description:
          "LoRA Composition merges LoRAs together. This opens up a whole new world for creative applications.",
      },
      {
        title: "Endless Combinations",
        description:
          "You can add up to five different LoRA models combining the strengths of each model, providing a more versatile and powerful tool for asset creation.",
      },
      {
        title: "The Sweet Spot",
        description:
          'Merging two or three models maximum usually strikes the right balance between different styles or subjects. Each model in the mix gets a strength slider, so you can fine-tune your composition to perfection. It’s generally a good idea to have one "main" model with "secondary" models supporting it. For example, you might set the first model to 0.8, with the others at 0.4 or 0.3.',
      },
      {
        title: "Diverse Asset Creation",
        description:
          '"Composing" models helps you create detailed characters and complex environments with precise balance and nuanced styles. This approach allows for greater flexibility and stylistic precision in your creative projects.',
      },
      {
        title: "Tailor your Outputs",
        description:
          "Adjust the model composition to match different game genres or artistic styles. Ensure each asset fits perfectly within its intended context by fine-tuning the settings and parameters.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/compose-a-model",
  },
  "composition-models": {
    steps: [
      {
        title: "Add Models",
        description:
          'Find the model you want to add and click "add". You will now have a strength slider to determine the amount of influence the model has in the overall composition.',
      },
      {
        title: "The Model Page",
        description:
          "If you click on a model you can view its unique model page. It includes a description of the model as well as example images. You can also view any images you’ve recently generated with the model.",
      },
      {
        title: "Recommended Compositions",
        description:
          'The model page will also display other models Scenario recommends for composing with that model. You can add these models directly to your composition by clicking "add".',
      },
      {
        title: "Name your Model",
        description:
          "After you’ve added the models you want to your composition, give it a name and then click the test button.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/compose-a-model",
  },
  "composition-tests": {
    steps: [
      {
        title: "Dial it In",
        description:
          "Create an example prompt and generate some images. Observe the behavior of the composition and increase or decrease the strength sliders of each model as you desire.",
      },
      {
        title: "The Right Balance",
        description:
          "Be careful not to keep all of the sliders at very high levels. A general rule is to reduce the upper limit of all the strength sliders the higher the number of models that are part of the composition.",
      },
      {
        title: "Remember Prompts and Unique Tokens",
        description:
          "If you are composing with a custom unique character model, be sure to include its token in your prompts when you test. Use similar prompts to the ones that work well on the models in the composition.",
      },
      {
        title: "Save Your Composition",
        description:
          "After your tests, close the testing window and Save your Composition. You can now prompt normally on your new Model.",
      },
      {
        title: "Easily Adjust Your Model Later",
        description:
          "At any time you wish you can modify the balance of strength given to each model in the composition. You can do this temporarily or save the new settings permanently.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/compose-a-model",
  },
  "vectorize-intro": {
    steps: [
      {
        title: "Vectorize",
        description:
          "Vectorize lets you transform images into scalable vector graphics (SVGs). Upload the input image, adjust settings for optimal results, and export the vector for use in design or animation software, or game development platforms such as Unity or Unreal.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/understanding-the-basics-of-image-vectorization",
  },
  "pixelate-intro": {
    steps: [
      {
        title: "Pixelate",
        description:
          "Pixelate transforms images into stylized pixel art, perfect for pixel-based gaming environments. By customizing the pixel grid size, color palette, and utilizing noise and background removal, it ensures assets are consistent, clear, and seamlessly integrated into game levels or interfaces.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/using-the-pixelate-feature",
  },
  "restyle-intro": {
    steps: [
      {
        title: "Restyle",
        description:
          "Transform any image with fine control over the amount of style transferred while maintaining structural integrity.",
      },
      {
        title: "Style Images",
        description:
          "For ultimate control over your enhancements, upload up to 10 images that represent your desired style and adjust the Style Fidelity slider as needed. Enhance will learn from these images and match their style, eliminating the need to over-engineer your prompts.<br>Simply tweak the parameters and let the magic happen.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/style-transfer-functionality",
  },
  "texture-intro": {
    steps: [
      {
        title: "Texture Generation",
        description:
          "Generate custom seamless textures using AI models. Choose from Scenario's models or use your own models to define the textures' style and details.",
      },
      {
        title: "Choose your Texture model (or train it)",
        description:
          "Select from generic or style-specific models to fit your needs. The model used will influence the texture's style and characteristics.",
      },
      {
        title: "Train Your Own Texture Models",
        description:
          "Create custom textures by training your own models. Upload and train models to match specific styles and details, giving you complete creative control over the texture generation process.",
      },
      {
        title: "Edit display settings",
        description:
          "Customize display settings to visualize textures accurately. Adjust tiling, metalness, roughness, or environmental lighting to see the texture's appearance under various conditions.",
      },
      {
        title: "Using Reference Images",
        description:
          "Choose between Image-to-Image, which transfers colors and patterns from your reference, or Structure Mode, which focuses on structural elements like lines and shapes.<br><br>Both options allow for flexible guidance, enabling you to shape the final look of your texture while still maintaining control over its style and details.",
      },
      {
        title: "Using Reference Images",
        description:
          "Choose between Image-to-Image, which transfers colors and patterns from your reference, or Structure Mode, which focuses on structural elements like lines and shapes.<br><br>Both options allow for flexible guidance, enabling you to shape the final look of your texture while still maintaining control over its style and details.",
      },
      {
        title: "Edit maps Settings",
        description:
          "Fine-tune your PBR texture maps to perfection by adjusting key parameters. Control the height, shine, polish, and angles to achieve the desired surface effect.<br><br>Experiment with inversions for alternate looks, and use precise edits to ensure your texture integrates seamlessly with lighting and environmental conditions in your 3D projects.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/texture-generation",
  },
  "skybox-intro": {
    steps: [
      {
        title: "Skyboxes",
        description:
          "Effortlessly create 360° panoramic environments, ideal for games, VR, or virtual tours.<br><br>Choose from predefined styles or apply your own custom style for a personalized look.<br><br>Generate a draft, refine it, and preview your skybox in full 360° before exporting for seamless integration into your projects.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/how-to-generate-your-first-skybox",
  },
  "canvas-live": {
    steps: [
      {
        title: "Live",
        description:
          "Create and refine your artwork in real-time with custom AI models.<br><br>Start by sketching your concept, and watch as it transforms instantly with real-time rendering. Make precision edits with selective inpainting, adjusting only the areas that need refinement while keeping the rest intact.<br><br>Enhance the final output for a polished, high-resolution, production-ready result.",
      },
    ],
    knowledgeCenterLink: "https://help.scenario.com/en/articles/scenario-live",
  },
  "remove-background-intro": {
    steps: [
      {
        title: "Remove Background",
        description:
          "Effortlessly remove backgrounds from your assets.<br>Select or upload images to quickly isolate subjects for use in various compositions. Replace backgrounds with transparency or customize them with any color to match your design.",
      },
    ],
    knowledgeCenterLink:
      "https://help.scenario.com/en/articles/removing-the-background-from-an-image",
  },
};
