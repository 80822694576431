import { useEffect, useRef, useState } from "react";
import useDeepMemo from "domains/commons/hooks/useDeepMemo";

interface UseDebounceArgs<T> {
  delay?: number;
  options: {
    preventFor?: (value: T) => boolean;
    resetBeforeDebounce?: T extends undefined ? boolean : undefined | false;
  };
}

/**
 * Debounce the passed value
 *
 * @param value The value to debounce.
 * @param delay Number of milliseconds to wait before debounce.
 * @param options.preventFor A function that returns if the debounce is prevented, to immediately return the new value. This is an optional param.
 * @param options.resetBeforeDebounce Return immediately `undefined` on `value` change before the debounce. This optional param is only available if `value` can be `undefined`.
 */
export function useDebounce<T>(
  value: T,
  delay?: UseDebounceArgs<T>["delay"],
  options: UseDebounceArgs<T>["options"] = {}
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const memoizedValue = useDeepMemo(value);
  const effectParamsRef = useRef<UseDebounceArgs<T>>({ delay, options });

  effectParamsRef.current.delay = delay;
  effectParamsRef.current.options = options;

  useEffect(() => {
    const delay = effectParamsRef.current.delay;
    const { preventFor, resetBeforeDebounce } = effectParamsRef.current.options;

    const prevented = !!(preventFor && preventFor(memoizedValue));
    if (prevented) {
      setDebouncedValue(memoizedValue);
    } else {
      if (resetBeforeDebounce) {
        setDebouncedValue(undefined as T);
      }
      const timer = setTimeout(
        () => setDebouncedValue(memoizedValue),
        delay || 500
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [memoizedValue]);

  return debouncedValue;
}
