import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";

interface SectionsAllProviderContextValue {
  collapsedSectionsById: { [key: string]: string[] };
  initById: (id: string, defaultCollapsedSections?: string[]) => void;
  destroyById: (id: string) => void;
  toggleCollapsedSectionById: (
    id: string,
    section: string,
    isCollapsed?: boolean
  ) => void;
  openSectionById: (id: string, section: string) => void;
}

export const SectionsAllContext =
  createContext<SectionsAllProviderContextValue>({
    collapsedSectionsById: {},
    initById: () => {},
    destroyById: () => {},
    toggleCollapsedSectionById: () => {},
    openSectionById: () => {},
  });

interface SectionsAllProviderProps extends PropsWithChildren {}

export function SectionsAllProvider({
  children = <></>,
}: SectionsAllProviderProps) {
  const [collapsedSectionsById, setCollapsedSections] = useState<{
    [key: string]: string[];
  }>({});

  const initById = useCallback(
    (id: string, defaultCollapsedSections?: string[]) => {
      setCollapsedSections((collapsedSectionsById) => ({
        ...collapsedSectionsById,
        [id]: defaultCollapsedSections ?? [],
      }));
    },
    [setCollapsedSections]
  );

  const destroyById = useCallback(
    (id: string) => {
      setCollapsedSections((collapsedSectionsById) =>
        _.omit(collapsedSectionsById, [id])
      );
    },
    [setCollapsedSections]
  );

  const toggleCollapsedSectionById = useCallback(
    (id: string, section: string, isCollapsed?: boolean) => {
      setCollapsedSections((collapsedSectionsById) => {
        if (
          isCollapsed === true ||
          (isCollapsed === undefined &&
            !collapsedSectionsById[id].includes(section))
        ) {
          return {
            ...collapsedSectionsById,
            [id]: _.uniq([...collapsedSectionsById[id], section]),
          };
        } else {
          return {
            ...collapsedSectionsById,
            [id]: _.without(collapsedSectionsById[id], section),
          };
        }
      });
    },
    [setCollapsedSections]
  );

  const openSectionById = useCallback(
    (id: string, section: string) => {
      toggleCollapsedSectionById(id, section, false);
    },
    [toggleCollapsedSectionById]
  );

  const sectionsAllContextValue = useMemo(
    () => ({
      collapsedSectionsById,
      toggleCollapsedSectionById,
      openSectionById,
      initById,
      destroyById,
    }),
    [
      collapsedSectionsById,
      toggleCollapsedSectionById,
      openSectionById,
      initById,
      destroyById,
    ]
  );

  // ----------------------------------

  return (
    <SectionsAllContext.Provider value={sectionsAllContextValue}>
      {children}
    </SectionsAllContext.Provider>
  );
}

export function useSectionsAllContext() {
  return useContext<SectionsAllProviderContextValue>(SectionsAllContext);
}

// ------------------------------------

interface SectionsProviderContextValue {
  collapsedSections: string[];
  toggleCollapsedSection: (section: string, isCollapsed?: boolean) => void;
  openSection: (section: string) => void;
  openSectionFromOutside: (id: string, section: string) => void;
}

export const SectionsContext = createContext<SectionsProviderContextValue>({
  collapsedSections: [],
  toggleCollapsedSection: () => {},
  openSection: () => {},
  openSectionFromOutside: () => {},
});

interface SectionsProviderProps extends PropsWithChildren {
  id?: string;
  defaultCollapsedSections?: string[];
}

export function SectionsProvider({
  id,
  defaultCollapsedSections = [],
  children = <></>,
}: SectionsProviderProps) {
  const defaultCollapsedSectionsRef = useRef<string[]>(
    defaultCollapsedSections
  );
  const {
    collapsedSectionsById,
    toggleCollapsedSectionById,
    openSectionById,
    initById,
    destroyById,
  } = useSectionsAllContext();

  const sectionId = useMemo(
    () =>
      id ??
      `section-${String(new Date().getTime() * Math.random()).replace(
        ".",
        ""
      )}`,
    [id]
  );
  const collapsedSections = useMemo(
    () =>
      collapsedSectionsById[sectionId] ?? defaultCollapsedSectionsRef.current,
    [collapsedSectionsById, sectionId]
  );

  const toggleCollapsedSection = useCallback(
    (section: string, isCollapsed?: boolean) =>
      toggleCollapsedSectionById(sectionId, section, isCollapsed),
    [toggleCollapsedSectionById, sectionId]
  );

  const openSection = useCallback(
    (section: string) => {
      openSectionById(sectionId, section);
    },
    [openSectionById, sectionId]
  );

  const openSectionFromOutside = useCallback(
    (outsideId: string, section: string) => openSectionById(outsideId, section),
    [openSectionById]
  );

  // ----------------------------------

  useEffect(() => {
    initById(sectionId, defaultCollapsedSectionsRef.current);
    return () => destroyById(sectionId);
  }, [destroyById, sectionId, initById]);

  // ----------------------------------

  const sectionsContextValue = useMemo(
    () => ({
      collapsedSections,
      toggleCollapsedSection,
      openSection,
      openSectionFromOutside,
    }),
    [
      collapsedSections,
      toggleCollapsedSection,
      openSection,
      openSectionFromOutside,
    ]
  );

  return (
    <SectionsContext.Provider value={sectionsContextValue}>
      {children}
    </SectionsContext.Provider>
  );
}

export function useSectionsContext() {
  return useContext<SectionsProviderContextValue>(SectionsContext);
}
