import React, { MouseEventHandler, useCallback } from "react";
import { useRouter } from "next/router";
import { GuideKeys } from "domains/guide/constants/guides";
import { useTourGuideContext } from "domains/guide/contexts/TourGuideProvider";
import { extraTheme } from "domains/theme";
import Icon, { IconProps } from "domains/ui/components/Icon";

interface TourGuideIconProps extends Omit<IconProps, "id"> {
  guide: GuideKeys;
}

export default function TourGuideIcon({ guide, ...props }: TourGuideIconProps) {
  const router = useRouter();
  const isOnCanvas = router.asPath.startsWith("/canvas");
  const isOnTexture = router.asPath.startsWith("/texture");

  const { forceShowGuide } = useTourGuideContext();

  const handleClick = useCallback<MouseEventHandler<SVGElement>>(
    (e) => {
      e.stopPropagation();
      forceShowGuide(guide);
    },
    [forceShowGuide, guide]
  );

  if (isOnCanvas || (isOnTexture && !guide.includes("texture"))) {
    return null;
  }

  return (
    <Icon
      id="Ui/Help"
      transition={extraTheme.transitions.fast}
      color="textTertiary"
      onClick={handleClick}
      cursor="pointer"
      {...props}
    />
  );
}
