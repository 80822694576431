import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Menu: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    groupTitle: {
      fontSize: "12px",
      fontWeight: "400",
      color: "textTertiary",
      ml: 2,
    },
    list: {
      bg: "background.500",
      border: "1px solid",
      borderColor: "border.500",
      borderRadius: "xl",
      pr: 2,
      pl: 2,
      pb: 2,
      pt: 1.5,
      minW: "unset",
      maxH: "400px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    item: {
      bg: "background.500",
      p: 0,
      mt: 0.5,
    },
    divider: {
      mx: -2,
    },
  },
  variants: {
    blurred: {
      list: {
        px: 1.5,
        borderColor: "#333333",
        backdropFilter: "blur(25px)",
        bgColor: "rgba(36, 36, 36, 0.8)",
        boxShadow: "none",
      },
      item: {
        h: 8,
        mt: 1,
        fontWeight: "500",
        _hover: {
          color: "#FFF",
          bgColor: "backgroundTertiary.500",
        },
      },
      divider: {
        m: 2,
      },
    },
  },
});
