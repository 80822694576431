import _, { isArray } from "lodash";

import { LocalStorageService } from "./localStorageService";

export const CURRENT_VERSION = 9;

type MigrationFunction = (localStorageService: LocalStorageService) => void;

interface Migration {
  version: number;
  migrate: MigrationFunction;
}

const migrationLogic: Migration[] = [
  {
    version: 2,
    migrate: (localStorageService) => {
      localStorageService.set(
        "persistentDisplayOptions",
        localStorageService.get("persistantDisplayOptions")
      );
      localStorageService.remove("persistantDisplayOptions");
    },
  },
  {
    version: 3,
    migrate: (localStorageService) => {
      const oldDisplayOptions = localStorageService.get<{
        numberOfColumns: number;
        gridView: string;
      }>("persistentDisplayOptions");
      if (oldDisplayOptions) {
        localStorageService.set(
          "numberOfColumns",
          oldDisplayOptions.numberOfColumns
        );
        localStorageService.set("gridView", oldDisplayOptions.gridView);
        localStorageService.remove("persistentDisplayOptions");
      }
    },
  },
  {
    version: 4,
    migrate: (localStorageService) => {
      const oldGridView = localStorageService.get("gridView");
      let gridView = "";
      if (_.includes(["column"], oldGridView)) gridView = "masonry";
      if (_.includes(["fullGrid", "fillInGrid", "fill_in_grid"], oldGridView))
        gridView = "fill";
      if (_.includes(["centeredGrid", "fitInGrid", "fit_in_grid"], oldGridView))
        gridView = "fit";

      if (gridView) localStorageService.set("gridView", gridView);
      else localStorageService.remove("gridView");
    },
  },
  {
    version: 5,
    migrate: (localStorageService) => {
      const oldSelectedTeam = localStorageService.get("selectedTeam");
      const newSelectedTeam = oldSelectedTeam
        ? {
            ...oldSelectedTeam,
            plan: "free",
          }
        : undefined;

      if (newSelectedTeam)
        localStorageService.set("selectedTeam", newSelectedTeam);
    },
  },
  // yes they are the same, yes it's on purpose
  {
    version: 6,
    migrate: (localStorageService) => {
      const oldSelectedTeam = localStorageService.get("selectedTeam");
      const newSelectedTeam = oldSelectedTeam
        ? {
            ...oldSelectedTeam,
            plan: "free",
          }
        : undefined;

      if (newSelectedTeam)
        localStorageService.set("selectedTeam", newSelectedTeam);
    },
  },
  {
    version: 7,
    migrate: (localStorageService) => {
      const modelsFilterStatus = localStorageService.get("modelsFilterStatus");
      if (modelsFilterStatus) {
        localStorageService.set("modelsFilterPresets", modelsFilterStatus);
      }

      const oldModelsFilterTags =
        localStorageService.get<string[]>("modelsFilterTags");
      if (oldModelsFilterTags) {
        const newModelsFilterTags = oldModelsFilterTags.filter(
          (tag) => !tag.startsWith("sc:subject:")
        );
        localStorageService.set("modelsFilterTags", newModelsFilterTags);
      }

      localStorageService.remove("modelsFilterTypes");
      localStorageService.remove("modelsFilterStatus");
    },
  },
  {
    version: 8,
    migrate: (localStorageService) => {
      const modelsFilterBases = localStorageService.get<string[] | undefined>(
        "modelsFilterBases"
      );
      if (modelsFilterBases && modelsFilterBases.includes("flux.1")) {
        const newValues = modelsFilterBases.filter((base) => base !== "flux.1");
        localStorageService.set("modelsFilterBases", [...newValues, "flux"]);
      }
    },
  },
  {
    version: 9,
    migrate: (localStorageService) => {
      const modelsFilterBases = localStorageService.get<
        string[] | string[][] | undefined
      >("modelsFilterBases");
      if (modelsFilterBases) {
        const newValues = modelsFilterBases.filter((base) => !isArray(base));
        localStorageService.set("modelsFilterBases", newValues);
      }
    },
  },
  {
    version: 9,
    migrate: (localStorageService) => {
      localStorageService.remove("fileManagerImage");
    },
  },
];

export default migrationLogic;
