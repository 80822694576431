import { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { AuthRedirectGuard } from "domains/auth/components/AuthRedirectGuard";
import { BackgroundTaskProvider } from "domains/background-tasks/contexts/BackgroundTaskProvider";
import { CanvasLockProvider } from "domains/canvas/hooks/useCanvasLock";
import { DragDropBackend } from "domains/commons/dnd";
import { TourGuideProvider } from "domains/guide/contexts/TourGuideProvider";
import { SectionsAllProvider } from "domains/inference/contexts/SectionsProvider";
import { IntlProvider } from "domains/messages/components/IntlProvider";
import { CreateNewModelModalProvider } from "domains/models/contexts/CreateNewModelModalProvider";
import { ChatProvider } from "domains/navigation/contexts/ChatProvider";
import { NavBarProvider } from "domains/navigation/contexts/NavBarProvider";
import { PlanProvider } from "domains/teams/contexts/PlanProvider";
import { TeamProvider } from "domains/teams/contexts/TeamProvider";
import theme from "domains/theme";
import Layout from "domains/ui/components/Layout";
import OnboardingForm from "domains/user/components/OnboardingForm";
import PolicyAcceptance from "domains/user/components/PolicyAcceptance";
import { UserProvider } from "domains/user/contexts/UserProvider";
import { WebsocketProvider } from "domains/websocket/contexts/Websocket";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import POSTHOG_ID from "infra/analytics/constants/PosthogId";
import Track from "infra/analytics/Track";
import ErrorBoundary from "infra/errors/ErrorBoundary";
import store from "infra/store/store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore moment/min/moment-with-locales does exist but ts doesn't see it that way
import moment from "moment/min/moment-with-locales";
import posthog from "posthog-js";
import { DndProvider } from "react-dnd";
import { Provider as ReduxProvider } from "react-redux";

import { CSSReset } from "@chakra-ui/css-reset";
import { ChakraProvider } from "@chakra-ui/react";
import { ClerkProvider } from "@clerk/nextjs";
import { SpeedInsights } from "@vercel/speed-insights/next";

import "domains/theme/globals.css";
// We need to import this for the canvas, if we place it in another file than _app it will break the style once build
import "domains/canvas/css/blueprint.css";
import "domains/ui/components/Markdown/Viewer/css/markdown-viewer.css";
// eslint-disable-next-line no-restricted-imports
import "../../public/lib/flag-icons/css/styles.css";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [lastBuildIdFetch, setLastBuildIdFetch] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (lastBuildIdFetch && Date.now() - lastBuildIdFetch < 1_000 * 60 * 5) {
      return;
    }
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    void fetch("/api/build-id")
      .then((res) => res.json())
      .then((data) => {
        const clientBuildId = process.env.NEXT_PUBLIC_BUILD_ID;
        const serverBuildId = data.buildId;
        if (clientBuildId !== serverBuildId) {
          window.location.reload();
        }
      });
    setLastBuildIdFetch(Date.now());
  }, [lastBuildIdFetch, router.pathname]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      posthog.init(POSTHOG_ID, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_PROXY,
        autocapture: false,
      });
    }
  }, []);

  useEffect(() => {
    moment.locale(navigator.language || "en-US");
  }, []);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Mac") === -1) {
      document.body.setAttribute("class", "custom-scrollbar");
    }
  }, []);

  useEffect(() => {
    if (router.asPath.startsWith("/generators")) {
      Track(AnalyticsEvents.Model.UsedDeprecatedGeneratorRoute, {
        path: router.asPath,
      });
      void router.replace(
        router.asPath.replace("/generators", "/models") as "/models"
      );
    }
    if (router.query.generatorId) {
      Track(AnalyticsEvents.Model.UsedDeprecatedGeneratorId, {
        pathname: router.pathname,
        query: router.query,
      });
      const query = { ...router.query };
      query.modelId = router.query.generatorId;
      delete query.generatorId;
      void router.replace({
        pathname: router.pathname,
        query,
      });
    }
  }, [router]);

  return (
    <ChakraProvider theme={theme}>
      <IntlProvider>
        <ClerkProvider>
          <AuthRedirectGuard>
            <NextSeo
              titleTemplate="%s | Scenario"
              openGraph={{
                title: "Scenario - AI-generated game assets",
                description:
                  "Craft unique and style-consistent game assets with custom-trained AI models",
                images: [
                  {
                    url: "/og.png",
                    height: 1_068,
                    width: 2_048,
                  },
                ],
              }}
              twitter={{
                cardType: "summary_large_image",
                handle: "@scenario_gg",
                site: "https://scenario.com",
              }}
            />
            <ReduxProvider store={store}>
              <UserProvider>
                <TeamProvider>
                  <NavBarProvider>
                    <CreateNewModelModalProvider>
                      <CSSReset />
                      <ErrorBoundary>
                        <WebsocketProvider>
                          <PlanProvider>
                            <ChatProvider>
                              <BackgroundTaskProvider>
                                <Layout>
                                  <CanvasLockProvider>
                                    <TourGuideProvider>
                                      <DndProvider backend={DragDropBackend}>
                                        <SectionsAllProvider>
                                          <Component {...pageProps} />
                                          <PolicyAcceptance />
                                          <OnboardingForm />
                                          <SpeedInsights />
                                        </SectionsAllProvider>
                                      </DndProvider>
                                    </TourGuideProvider>
                                  </CanvasLockProvider>
                                </Layout>
                              </BackgroundTaskProvider>
                            </ChatProvider>
                          </PlanProvider>
                        </WebsocketProvider>
                      </ErrorBoundary>
                    </CreateNewModelModalProvider>
                  </NavBarProvider>
                </TeamProvider>
              </UserProvider>
            </ReduxProvider>
          </AuthRedirectGuard>
        </ClerkProvider>
      </IntlProvider>
    </ChakraProvider>
  );
}
