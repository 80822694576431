import { useCallback, useEffect, useRef } from "react";
import Button from "domains/ui/components/Button";
import Icon, { IconId } from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  HStack,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  // eslint-disable-next-line no-restricted-imports
  MenuItem,
  MenuItemProps,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";

const LINKS: {
  id: string;
  iconId: IconId;
  title: string;
  text: string;
  indicator?: boolean;
  externalLink?: LinkProps["href"];
  onClick?: "openChangelog" | MenuItemProps["onClick"];
}[] = [
  {
    id: "whatsNew",
    iconId: "Domains/Links/WhatsNew",
    title: "What's new ?",
    text: "Discover our recent additions and updates.",
    onClick: "openChangelog",
  },
  {
    id: "knowledgeCenter",
    iconId: "Domains/Links/KnowledgeCenter",
    title: "Knowledge Center",
    text: "Explore our comprehensive Knowledge Center.",
    externalLink: "https://help.scenario.com/",
  },
  {
    id: "discord",
    iconId: "Domains/Links/Discord",
    title: "Community Hub",
    text: "Join our Discord for networking and support.",
    externalLink: "https://discord.com/invite/scenario",
  },
  {
    id: "feedback",
    iconId: "Domains/Links/Feedback",
    title: "Feedback & Suggestions",
    text: "Request or upvote upcoming features.",
    externalLink: "https://feedback.scenario.com/",
  },
  {
    id: "referral",
    iconId: "Domains/Links/Referral",
    title: "Refer a friend",
    text: "Earn 20% on paid referrals.",
    externalLink: "https://affiliates.scenario.com",
  },
  {
    id: "unity",
    iconId: "Domains/Links/Unity",
    title: "Unity plugin",
    text: "Use Scenario directly within Unity.",
    externalLink: process.env.NEXT_PUBLIC_UNITY_PLUGIN_URL!,
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedUnity);
    },
  },
];

export default function LinkButtonDropdown() {
  const changelogWidgetRef = useRef<typeof window.HellonextWidget>();

  const handleChangelogClick = () => {
    if (!changelogWidgetRef.current) return;
    changelogWidgetRef.current.open();
  };

  const handleChangelogInit = useCallback(() => {
    if (!changelogWidgetRef.current) return;
    changelogWidgetRef.current.fallbackTriggerWrapperRef.style.width = 0;
    changelogWidgetRef.current.fallbackTriggerWrapperRef.style.height = 0;
    changelogWidgetRef.current.fallbackTriggerWrapperRef.style.right = 0;
    changelogWidgetRef.current.fallbackTriggerIFrameRef.contentWindow.document.getElementsByTagName(
      "html"
    )[0].style.colorScheme = "dark";
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!window.HellonextWidget || changelogWidgetRef.current) {
        return;
      }

      changelogWidgetRef.current = new window.HellonextWidget({
        modules: ["changelog"],
        type: "modal",
        openFrom: "center",
        theme: "dark",
        accent: {
          background: "#000000",
          primary: "#202020",
          button: "#0087CC",
        },
        token: "VKgUl5QrSsrVhpOs5s6VZA",
        postFilters: {
          bucket_id: [11_723, 11_796],
        },
        changelogFilters: {
          labels: [48_260, 48_261],
          per_page: 5,
        },
        customizations: {
          widgetWidth: "min(90%, 800px)",
        },
        autoOpenWhenNewChangelog: true,
        onInitialized: handleChangelogInit,
      });

      changelogWidgetRef.current.init();
    }, 1);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [handleChangelogInit]);

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        pos="relative"
        w="36px"
        h="36px"
        p={0}
        borderRadius="full"
        _hover={{
          bgColor: "backgroundTertiary.500",
        }}
        variant="unstyled"
      >
        <Icon id="Layout/Link" h="20px" color="textSecondary" />
      </MenuButton>

      <MenuList w="380px">
        {LINKS.map((link) => {
          const onLinkClick = (() => {
            if (link.onClick === "openChangelog") {
              return handleChangelogClick;
            } else if (typeof link.onClick === "function") {
              return link.onClick;
            }
          })();

          return (
            <MenuItem
              key={link.id}
              {...(link.externalLink
                ? {
                    as: Link,
                    href: link.externalLink,
                    isExternal: true,
                    variant: "unstyled",
                  }
                : {})}
              p={2}
              borderRadius="md"
              _hover={{ bgColor: "backgroundTertiary.800" }}
              data-group
              onClick={onLinkClick}
            >
              <HStack align="center" spacing={3}>
                <Box pos="relative" w="40px" h="40px">
                  <HStack
                    justify="center"
                    w="100%"
                    h="100%"
                    bg="whiteAlpha.100"
                    borderRadius="md"
                  >
                    <Icon id={link.iconId} />
                  </HStack>
                </Box>
                <VStack align="flex-start" flex={1} spacing={0}>
                  <Text size="body.bold.md">{link.title}</Text>
                  <Text color="textSecondary" size="body.sm">
                    {link.text}
                  </Text>
                </VStack>
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
