import posthog from "posthog-js";

export default function Track(key: string, properties?: Record<string, any>) {
  try {
    posthog.capture(
      key,
      properties
        ? {
            ...properties,
            app: "app.scenario.com",
          }
        : {}
    );
  } catch (err) {
    console.error(
      "Error while tracking",
      err,
      "key",
      key,
      "properties",
      properties || ""
    );
  }
}
