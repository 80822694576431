import { useEffect, useRef, useState } from "react";

export function useHover<T>(isActive = true): [React.RefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    () => {
      if (!isActive) {
        setValue(false);
        return;
      }

      const node: any = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    // the ref is necessary as a dependency but eslint says it's not
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current, isActive] // Recall only if ref changes
  );
  return [ref, value];
}

export function useChildrenHover<T extends HTMLDivElement>(): [
  React.RefObject<T>,
  string | undefined
] {
  const [hoveredElement, setHoveredElement] = useState<string | undefined>();
  const ref = useRef<T>(null);

  useEffect(
    () => {
      function onMouseOver(this: HTMLDivElement, e: MouseEvent) {
        const target = e.target instanceof HTMLElement ? e.target : undefined;
        const hoverId =
          (target &&
            target.closest("[data-hover-id]")?.getAttribute("data-hover-id")) ||
          undefined;
        setHoveredElement(hoverId);
      }

      function onMouseOut(this: HTMLDivElement, e: MouseEvent) {
        const target = e.target instanceof HTMLElement ? e.target : undefined;
        const hoverId =
          (target &&
            target.closest("[data-hover-id]")?.getAttribute("data-hover-id")) ||
          undefined;
        setHoveredElement((hoveredElement) =>
          hoverId === hoveredElement ? undefined : hoveredElement
        );
      }

      const element = ref.current;
      if (!element) return;
      element.addEventListener("mouseover", onMouseOver);
      element.addEventListener("mouseout", onMouseOut);
      return () => {
        element.removeEventListener("mouseover", onMouseOver);
        element.removeEventListener("mouseout", onMouseOut);
      };
    },
    // the ref is necessary as a dependency but eslint says it's not
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );

  return [ref, hoveredElement];
}

/*

export function useMultipleHover<T extends HTMLDivElement>(
  elements: string[]
): [MutableRefObject<{ [key: string]: T }>, string | undefined] {
  const [hoveredElement, setHoveredElement] = useState<string | undefined>();
  const elementsRef = useRef<{ [key: string]: T }>({});

  useEffect(() => {
    function onMouseOver(this: HTMLDivElement) {
      const elementId = this.getAttribute("data-hover-id") || undefined;
      setHoveredElement(elementId);
    }

    function onMouseOut(this: HTMLDivElement) {
      const elementId = this.getAttribute("data-hover-id") || undefined;
      setHoveredElement((hoveredElement) =>
        elementId === hoveredElement ? undefined : hoveredElement
      );
    }

    const clearEffectActions: (() => void)[] = [];
    for (const elementId of elements) {
      const element = elementsRef.current[elementId];
      if (!element) continue;
      console.log("setEvent");
      element.setAttribute("data-hover-id", elementId);
      element.addEventListener("mouseover", onMouseOver);
      element.addEventListener("mouseout", onMouseOut);
      clearEffectActions.push(() => {
        element.removeEventListener("mouseover", onMouseOver);
        element.removeEventListener("mouseout", onMouseOut);
      });
    }

    return () => {
      for (const clearEffectAction of clearEffectActions) {
        clearEffectAction();
      }
    };
  }, [elements]);

  return [elementsRef, hoveredElement];
}

*/
