import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ModalFailedPayment from "domains/teams/components/ModalFailedPayment";
import ModalPlanQuotaLimit, {
  ContentProps,
} from "domains/teams/components/ModalPlanQuotaLimit";
import { PLANS } from "domains/teams/constants/Pricing";
import {
  MAP_OLD_PLANS_TO_NEW,
  useTeamContext,
} from "domains/teams/contexts/TeamProvider";
import {
  PlanId,
  PlanQuotaLimit,
  Subscription,
} from "domains/teams/interfaces/Pricing";
import useWebsocketMessage from "domains/websocket/hooks/useWebsocketMessage";
import {
  GetTeamsLimitsByTeamIdApiResponse,
  GetTeamsSubscriptionsByTeamIdApiResponse,
  useGetTeamsLimitsByTeamIdQuery,
  useLazyGetTeamsSubscriptionsByTeamIdQuery,
} from "infra/api/generated/api";
import _ from "lodash";
import moment from "moment";

import { useAuth } from "@clerk/nextjs";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/react";

type CheckUserLimitsDomain = "canvas";

type WebsocketMessage = {
  topic: "team.billing.creativeUnits";
  payload: {
    time: number;
    amountConsumedCreativeUnits: number;
    remainingCreativeUnits: number;
  };
};

export interface PlanProviderContext {
  maxInferenceBatchSize: number;
  checkUserLimits: (domain: CheckUserLimitsDomain) => void;
  showLimitModal: (
    limitId: PlanQuotaLimit,
    contentProps?: ContentProps
  ) => void;
  totalRemainingCreativeUnits: number | undefined;
  oneShotCreativeUnits: GetTeamsLimitsByTeamIdApiResponse["oneShotCreativeUnits"];
  oneShotRemainingCreativeUnits: number | undefined;
  planRemainingCreativeUnits: number | undefined;
  planCreativeUnits: number | undefined;
  seats: number;
  usedSeats: number;
  unassignedSeats: number;
  maxUpscalingFactor: number;
  subscription: Subscription | undefined;
  reloadSubscription: () => void;
}

export const PlanContext = createContext<PlanProviderContext>({
  maxInferenceBatchSize: 4,
  checkUserLimits: () => {},
  showLimitModal: () => {},
  totalRemainingCreativeUnits: undefined,
  oneShotCreativeUnits: [],
  oneShotRemainingCreativeUnits: undefined,
  planRemainingCreativeUnits: undefined,
  planCreativeUnits: undefined,
  seats: 0,
  usedSeats: 0,
  unassignedSeats: 0,
  maxUpscalingFactor: 16,
  subscription: undefined,
  reloadSubscription: () => {},
});

export function PlanProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeam } = useTeamContext();
  const { userId } = useAuth();
  const [getSubscriptionTrigger] = useLazyGetTeamsSubscriptionsByTeamIdQuery();
  const { data: limitData } = useGetTeamsLimitsByTeamIdQuery(
    selectedTeam.id && !!userId
      ? {
          teamId: selectedTeam.id,
        }
      : skipToken
  );
  const [forcedRemainingCreativeUnits, setForcedRemainingCreativeUnits] =
    useState<
      | {
          totalRemainingCreativeUnits: number | undefined;
          planRemainingCreativeUnits: number | undefined;
          oneShotRemainingCreativeUnits: number | undefined;
        }
      | undefined
    >();
  const [lastPlanUpdate, setLastPlanUpdate] = useState<Date>(new Date());
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const [modalPlanQuotaLimitId, setModalPlanQuotaLimitId] = useState<
    PlanQuotaLimit | undefined
  >();
  const [modalPlanQuotaLimitContentProps, setModalPlanQuotaLimitContentProps] =
    useState<ContentProps | undefined>();

  const remainingCreativeUnits = useMemo(() => {
    if (!limitData) {
      return {
        totalRemainingCreativeUnits: undefined,
        planRemainingCreativeUnits: undefined,
        oneShotRemainingCreativeUnits: undefined,
      };
    }

    const planRemainingCreativeUnits = Math.max(
      limitData.limits.creativeUnits - limitData.values.creativeUnits,
      0
    );
    const oneShotRemainingCreativeUnits = limitData.oneShotCreativeUnits.reduce(
      (acc, { remaining }) => acc + remaining,
      0
    );
    const totalRemainingCreativeUnits =
      planRemainingCreativeUnits + oneShotRemainingCreativeUnits;

    return {
      totalRemainingCreativeUnits,
      planRemainingCreativeUnits,
      oneShotRemainingCreativeUnits,
    };
  }, [limitData]);

  const lastPlanUpdatePeriodCount =
    lastPlanUpdate &&
    _.max([
      0,
      Math.floor(
        Math.abs(moment.utc(lastPlanUpdate).diff(moment.utc(), "minutes")) / 10
      ),
    ]);

  // ----------------------------------

  const showLimitModal = useCallback(
    (limitId: PlanQuotaLimit, contentProps?: ContentProps) => {
      setModalPlanQuotaLimitId(limitId);
      setModalPlanQuotaLimitContentProps(contentProps);
    },
    [setModalPlanQuotaLimitId, setModalPlanQuotaLimitContentProps]
  );

  const checkUserLimits = useCallback(
    (domain: CheckUserLimitsDomain) => {
      if (!limitData) {
        return;
      }

      if (
        domain === "canvas" &&
        limitData.limits.canvasAssets !== undefined &&
        limitData.limits.canvasAssets <= limitData.values.canvasAssets &&
        limitData.limits.canvasAssets !== -1
      ) {
        return showLimitModal("planCanvasProjects");
      }
    },
    [showLimitModal, limitData]
  );

  const reloadSubscription = useCallback(() => {
    setLastPlanUpdate(new Date());
  }, [setLastPlanUpdate]);

  // ----------------------------------

  const handleModalPlanQuotaLimitClose = useCallback(() => {
    setModalPlanQuotaLimitId(undefined);
    setModalPlanQuotaLimitContentProps(undefined);
  }, [setModalPlanQuotaLimitId, setModalPlanQuotaLimitContentProps]);

  // ----------------------------------

  useWebsocketMessage(
    "team.billing.creativeUnits",
    (message: WebsocketMessage) => {
      const { payload } = message;
      try {
        setForcedRemainingCreativeUnits((forcedRemainingCreativeUnits) => {
          const totalCu = payload.remainingCreativeUnits;
          const initialPlanCu =
            forcedRemainingCreativeUnits?.planRemainingCreativeUnits ??
            remainingCreativeUnits.planRemainingCreativeUnits;
          const planCu =
            initialPlanCu !== undefined
              ? _.max([0, initialPlanCu - payload.amountConsumedCreativeUnits])
              : undefined;
          const remainingConsumption =
            initialPlanCu !== undefined && planCu !== undefined
              ? payload.amountConsumedCreativeUnits - (initialPlanCu - planCu)
              : undefined;
          const initialOneShotCu =
            forcedRemainingCreativeUnits?.oneShotRemainingCreativeUnits ??
            remainingCreativeUnits.oneShotRemainingCreativeUnits;
          const oneShotCu =
            initialOneShotCu !== undefined
              ? _.max([0, initialOneShotCu - (remainingConsumption ?? 0)])
              : undefined;
          return {
            totalRemainingCreativeUnits: totalCu,
            planRemainingCreativeUnits: planCu,
            oneShotRemainingCreativeUnits: oneShotCu,
          };
        });
      } catch (err: any) {
        if (err.message === "Unknown websocket message") return;
        Sentry.captureException(err);
      }
    }
  );

  useEffect(() => {
    setForcedRemainingCreativeUnits(undefined);
  }, [setForcedRemainingCreativeUnits, selectedTeam.id]);

  useEffect(() => {
    if (!selectedTeam.id || !userId) return;
    void (async () => {
      try {
        setSubscription(undefined);

        const { data: subscriptionData } = await getSubscriptionTrigger({
          teamId: selectedTeam.id,
          paymentProvider: "stripe",
        });

        let planId = subscriptionData?.subscription?.plan as PlanId | undefined;
        if (planId) {
          planId = MAP_OLD_PLANS_TO_NEW[planId];
        }
        const plan = planId ? PLANS[planId] : undefined;

        if (!plan) throw new Error("unknown plan");

        const coming = subscriptionData?.subscription?.cancelAtPeriodEnd
          ? ({
              amount: 0,
              currency: "usd",
              interval: "month",
              plan: "free",
              seats: 1,
              startAt: subscriptionData?.period?.endAt,
            } as GetTeamsSubscriptionsByTeamIdApiResponse["coming"])
          : subscriptionData?.coming;

        const amount =
          (subscriptionData?.subscription?.amount ?? 0) +
          (subscriptionData?.subscription?.additionalSeatsAmount ?? 0);
        const comingAmount =
          (coming?.amount ?? 0) + (coming?.additionalSeatsAmount || 0);

        setSubscription({
          label: plan?.label,
          interval: subscriptionData?.subscription?.interval,
          plan: planId,
          amount: amount,
          additionalSeatUnitAmount: subscriptionData?.subscription?.seats
            ? (subscriptionData?.subscription?.additionalSeatsAmount ?? 0) /
              subscriptionData.subscription.seats
            : undefined,
          additionalSeatsAmount:
            subscriptionData?.subscription?.additionalSeatsAmount,
          seats: subscriptionData?.subscription?.seats,
          additionalSeats: subscriptionData?.subscription?.additionalSeats,
          isAlreadySubscribed: true,
          customerPortalUrl: subscriptionData?.customerPortalUrl,
          renewal: coming?.plan
            ? {
                date: coming?.startAt,
                amount: comingAmount,
                interval: coming?.interval,
                plan: coming?.plan,
                seats: coming?.seats,
                additionalSeats: coming?.additionalSeats,
                additionalSeatsAmount: coming?.additionalSeatsAmount,
              }
            : {
                date:
                  subscriptionData?.subscription?.periodEndAt ??
                  moment.utc().startOf("month").add(1, "month").toISOString(),
              },
          period: {
            startAt:
              subscriptionData?.period?.startAt ??
              moment.utc().startOf("month").toISOString(),
            endAt:
              subscriptionData?.period?.endAt ??
              moment.utc().startOf("month").add(1, "month").toISOString(),
          },
          autoRefill:
            subscriptionData?.subscription.autoRefill?.quantity &&
            subscriptionData?.subscription.autoRefill?.enabled === true
              ? {
                  quantity: subscriptionData.subscription.autoRefill.quantity,
                  threshold:
                    subscriptionData.subscription.autoRefill.threshold ?? 1_000,
                }
              : undefined,
        } as Subscription);
      } catch (err) {
        setSubscription({
          label: PLANS.free.label,
          plan: "free",
        } as Subscription);
      }
    })();
  }, [
    getSubscriptionTrigger,
    setSubscription,
    selectedTeam.id,
    selectedTeam.usersCount,
    lastPlanUpdate,
    lastPlanUpdatePeriodCount,
    userId,
  ]);

  // ----------------------------------

  return (
    <PlanContext.Provider
      value={{
        maxInferenceBatchSize: limitData?.limits.inferenceBatchSize ?? 4,
        checkUserLimits,
        showLimitModal,
        ...(forcedRemainingCreativeUnits ?? remainingCreativeUnits),
        oneShotCreativeUnits: limitData?.oneShotCreativeUnits ?? [],
        planCreativeUnits: limitData?.limits.creativeUnits,
        seats: limitData?.limits.seats ?? 0,
        usedSeats: limitData?.values.seats ?? 0,
        unassignedSeats:
          (limitData?.limits.seats ?? 0) - (limitData?.values.seats ?? 0),
        maxUpscalingFactor: limitData?.limits.scalingFactor ?? 16,
        subscription,
        reloadSubscription,
      }}
    >
      <ModalFailedPayment />
      <ModalPlanQuotaLimit
        isOpen={!!modalPlanQuotaLimitId}
        id={modalPlanQuotaLimitId}
        contentProps={modalPlanQuotaLimitContentProps}
        onClose={handleModalPlanQuotaLimitClose}
      />

      {children}
    </PlanContext.Provider>
  );
}
