import { StringArrayToType } from "domains/commons/ts-utils";
import { GetMeApiResponse } from "infra/api/generated/api";

export const NOTIFICATION_TYPES: (keyof NonNullable<
  GetMeApiResponse["user"]["notifications"]
>)[] = [
  "model-validation-failed",
  "model-transfer",
  "model-train-succeeded",
  "creative-units-threshold-50",
  "creative-units-threshold-90",
  "creative-units-threshold-100",
  "model-validation-failed",
  "soft-deletion-first-call",
  "soft-deletion-last-call",
] as const;

export type NotificationType = StringArrayToType<typeof NOTIFICATION_TYPES>;

export const NOTIFICATIONS_LABELS: { [key in NotificationType]: string } = {
  "model-transfer": "Model Transfer Completed",
  "model-train-succeeded": "Model Training Successful",
  "creative-units-threshold-50": "50% of Creative Units Reached",
  "creative-units-threshold-90": "90% of Creative Units Reached",
  "creative-units-threshold-100": "100% of Creative Units Reached",
  "model-validation-failed": "Model Upload Failed",
  "soft-deletion-first-call": "Soft Deletion First Call",
  "soft-deletion-last-call": "Soft Deletion Last Call",
};
